import { useEffect } from 'react';
import LocalStorageService from '../../api/LocalStoragrService';
import title from '../../Constants/title';

const localStorageService = LocalStorageService.getService();

const RequireAuth = ({ children }) => {
  useEffect(() => {
    const token = localStorageService.getAccessToken();
    const isAuthenticated = !!token;
    if (!isAuthenticated) {
      window.location.href = title.landing;
    }
  }, []); // Added dependency array to prevent multiple calls

  return children;
};

export default RequireAuth;
