import React from 'react';
import './style.scss';
import title from '../../Constants/title';

function Footer() {
  return (
    <div className="footer__copyright text-black dark:text-white">
      Copyright © 2024{' '}
      <a href="https://finbright.org" alt={title.name}>
        {title.name}
      </a>
      . All rights reserved.
    </div>
  );
}

export default Footer;
