const concord = {
    name: "ConcordProsper",
    logo: false,
    landing: 'https://landing.concordprosper.com'
}

const finbright = {
    name: "FinBright",
    logo: true,
    landing: 'https://landing.finbright.org'
}

export default finbright
