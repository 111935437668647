import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IoPulseSharp } from 'react-icons/io5'
import { GrOverview } from "react-icons/gr";
import { FaCoins } from "react-icons/fa";
import { MdOutlineBatchPrediction } from "react-icons/md";
import { GiRapidshareArrow } from "react-icons/gi";
import { SiQuicklook } from "react-icons/si";
import { PiStrategyBold } from "react-icons/pi";
import { SiPytest } from "react-icons/si";
import { RiGuideLine } from "react-icons/ri";
import { TbBasketSearch } from "react-icons/tb";

import { MdOutlineClose } from 'react-icons/md';
import {
  AiFillRead,
  AiFillExperiment,
  AiFillFilter,
  AiOutlineDashboard,
  AiOutlineDotChart,
} from 'react-icons/ai';
import { HiMenuAlt2 } from 'react-icons/hi';
import { FiChevronDown } from 'react-icons/fi';
import { RiAccountCircleLine } from 'react-icons/ri';
import { RxDotFilled } from 'react-icons/rx';
import { BiLogOut } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/config';
import cx from 'classnames';
import FinbrightIcon from './components/FinBrightIcon';
import './style.scss';
import useIsMobile from '../../hooks/useIsMobile';
import ToggleSwitch from '../Toggle';
import { set_toggle_state } from '../../pages/OverallMarket/slice';
import title from '../../Constants/title';

function SideBar() {
  const theme = useSelector((state) => state.overall.theme);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [fixedOpen, setFixedOpen] = useState(false);
  const handleSideBarHovorEnter = () => {
    if (!fixedOpen) {
      setOpen(true);
      setIsSubItemsOpen(isSubItemsOpen.map((_) => {
        return true
      }))
    }
  };
  const handleSideBarHovorLeave = () => {
    if (!fixedOpen) {
      setOpen(false);
      setIsSubItemsOpen(isSubItemsOpen.map((_) => {
        return false
      }))
    }
  };
  const [userName, setUserName] = useState('narimash');
  const [email, setEmail] = useState('n@gmail.com');
  const [strategyList, setStrategyList] = useState([]);
  const menus = [
    {
      name: 'Market Overview',
      link: '/market-overview',
      icon: GrOverview,
    },
    { name: 'Coin Overview',
      link: '/coin/BTC-USDT',
      icon: FaCoins 
    },
    {
      name: 'Prediction Models',
      icon: MdOutlineBatchPrediction,
      subItem: [
        {
          name: 'Rapid Insight',
          link: '/rapid-insight',
          icon: GiRapidshareArrow
        },
        {
          name: 'In-Depth Insight',
          link: '/in-depth-insight',
          icon: SiQuicklook
        },
      ],
    },
    {
      name: 'Trading Strategies',
      icon: PiStrategyBold,
      subItem: [
        {
          name: 'Trade Pulse',
          link: '/pulse',
          icon: IoPulseSharp
        },
        {
          name: 'Backtest Wizard',
          link: '/wizard',
          icon: SiPytest
        },
      ],
    },
    {
      name: 'Portfolio Analyzer',
      link: '/portfolio-analyzer',
      icon: TbBasketSearch,
    },
    // { name: 'Smart Portfolio', link: '/smart-portfolio', icon: AiFillFilter },
  ];
  const [isSubItemsOpen, setIsSubItemsOpen] = useState(Array(5).fill(false));
  useEffect(() => {
    axiosInstance
      .get('/auth/users/me/')
      .then((res) => {
        setUserName(res?.data?.username);
        setEmail(res?.data?.email);
      })
      .catch((_err) => { });
    axiosInstance
      .get('/backtest/strategyList/')
      .then((res) => setStrategyList(res.data))
      .catch((_err) => { });
  }, []);

  useEffect(() => {
  }, [isSubItemsOpen]);

  const isMobile = useIsMobile();

  return isMobile ? (
    <>
      <div className="sidebar-mobile text-black dark:text-white bg-light-back_secondary dark:bg-dark-back_secondary">
        <HiMenuAlt2
          size={36}
          className="cursor-pointer"
          onClick={() => setFixedOpen(true)}
        />
        <Link to="/market-overview" className="sidebar-mobile__logo">
          {title.logo ? (
            <FinbrightIcon size={32} />
          ): null}
          <div className="sidebar-mobile__logo-text text-black dark:text-white">{title.name}</div>
        </Link>
      </div>
      <div
        className={cx(
          'sidebar-mobile__content text-black dark:text-white bg-light-primary dark:bg-dark-back_secondary',
          {
            'sidebar-mobile__content--open': fixedOpen,
          }
        )}
      >
        <div className="sidebar-mobile__content-header">
          <MdOutlineClose onClick={() => setFixedOpen(false)} size={36} />
          <Link to="/market-overview" className="sidebar-mobile__logo">
          {title.logo ? (
            <FinbrightIcon size={32} />
          ): null}
            <div className="sidebar-mobile__logo-text text-black dark:text-white">{title.name}</div>
          </Link>
        </div>
        <div className="sidebar-mobile__footer">
          <div className="sidebar-mobile__user">
            <RiAccountCircleLine size={24} />
            <div>
              <p>{userName}</p>
              <p>{email}</p>
            </div>
          </div>
          <BiLogOut size={24} onClick={() => navigate('/logout')} />
        </div>
        {menus?.map((menu, i) => (
          <div key={i}>
            <Link
              to={menu?.link}
              key={i}
              className={`mt-5 pl-2 flex items-center whitespace-pre text-lg font-medium rounded-md`}
            >
              <div>{React.createElement(menu?.icon, { size: '20' })}</div>
              <h2
                className={`${'opacity-100 whitespace-pre font-semibold ml-2 px-0 py-0  flex justify-between w-full'}`}
              >
                {menu?.name}
              </h2>
              {menu.subItem && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsSubItemsOpen(
                      isSubItemsOpen.map((item, index) =>
                        index === i ? !item : item
                      )
                    );
                  }}
                  className={cx({
                    'rotate-180': isSubItemsOpen[i],
                  })}
                >
                  {<FiChevronDown />}
                </div>
              )}
            </Link>
            {isSubItemsOpen[i] &&
              menu.subItem.map((sub, i) => (
                <Link
                  key={i}
                  className={
                    'flex justify-start ml-7 px-3 pt-5 text-md items-center'
                  }
                  to={sub.link}
                >
                    <div className='px-2'>{React.createElement(sub?.icon, { size: '20' })}</div>
                  <p className='text-sm'>{sub.name}</p>
                </Link>
              ))}
          </div>
        ))}
        <div className="flex flex-row justify rounded-sm w-full p-2 mt-10 justify-between items-center">
          <p
            className={
              'opacity-100 whitespace-pre p-0 m-0 w-0 text-black dark:text-white text-lg'
            }
          >
            {theme ? 'Dark Mode' : 'Light Mode'}
          </p>
          <ToggleSwitch
            className={'flex'}
            value={theme}
            onChange={set_toggle_state}
          />
        </div>
      </div>
    </>
  ) : (
    <aside
      className="aside shadow-xl h-[98vh] grid grid-rows-6 gap-2 bg-side bg-light-back_secondary dark:bg-dark-back_secondary bordering pb-5 mb-5"
      onMouseEnter={() => handleSideBarHovorEnter()}
      onMouseLeave={() => handleSideBarHovorLeave()}
    >
      <div
        className={cx('row-span-5 duration-700 text-black dark:text-white px-4', {
          'w-60': fixedOpen,
          'w-80': open,
          'w-16 open': !open,
          'transition-width': true, // Apply transition only to the width property
        })} 
        style={{ paddingBottom: 32 }}
      >
        <div className="py-3 flex justify-start">
          <HiMenuAlt2
            size={26}
            className="cursor-pointer"
            onClick={() => setFixedOpen(!fixedOpen)}
          />
        </div>
        <div className="mt-4 flex flex-col relative">
          <div className="flex h-full items-center">
            <div
              onClick={() => {
                navigate('/');
              }}
              className="rounded-full object-cover align-center cursor-pointer flex"
            >
            {title.logo ? (
              <FinbrightIcon size={32} />
            ) : (
              !open && ( // Hide the letter when `open` is true
                <div className="flex items-center justify-center w-10 h-10 rounded-full text-white text-[1.8rem] font-extrabold">
                  {title.name?.charAt(0).toUpperCase()}
                </div>
              )
            )}
            </div>
            <div
              className={
                open ? 'opacity-100 duration-1000' : 'opacity-0 duration-300'
              }
            >
              <p className="pl-2 text-[1.8rem]  font-extrabold">{title.name}</p>
              {/* <p className="pl-2 text-l font-bold pt-6">{userName}</p>
              <p className="pl-2 text-[0.75rem]">{email}</p> */}
            </div>
          </div>
          {menus?.map((menu, i) => (
            <div key={i}>
              <Link
                to={menu?.link}
                key={i}
                className={`mt-5 pl-2 flex items-center whitespace-pre text-sm font-medium rounded-md`}
              >
                <div>{React.createElement(menu?.icon, { size: '20' })}</div>
                <h2
                  className={`${open
                      ? 'menu-secondary-enter-active whitespace-pre font-semibold ml-2 px-0 py-0  flex justify-between w-full'
                      : 'menu-secondary-exit-active whitespace-pre font-semibold ml-2 px-0 py-0 w-0'
                    }`}
                >
                  {menu.name}
                </h2>
                {menu.subItem && open && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setIsSubItemsOpen(
                        isSubItemsOpen.map((item, index) =>
                          index === i ? !item : item
                        )
                      );
                    }}
                    className={cx({
                      'rotate-180': isSubItemsOpen[i],
                    })}
                  >
                    {<FiChevronDown />}
                  </div>
                )}
              </Link>
              {menu.subItem?.map((sub, j) => (
                <Link
                  key={j}
                  to={sub.link}
                  className={
                    isSubItemsOpen[i]
                      ? 'menu-secondary-enter-active flex'
                      : 'menu-secondary-exit-active w-0 h-0'
                  }
                >
                  <h6
                    className={`${isSubItemsOpen[i]
                        ? 'menu-secondary-enter-active whitespace-pre px-8 pt-5 flex w-full'
                        : 'menu-secondary-exit-active hidden'
                      }`}
                  >
                    <div className='px-2'>{React.createElement(sub?.icon, { size: '20' })}</div>
                    <p className='text-sm'>{sub.name}</p>
                  </h6>
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="row-span-1 p-2 gap-2 flex flex-col">
        <div
          onClick={() => navigate('/logout')}
          className="bg-white text-black dark:bg-dark-side dark:text-white flex flex-row h-fit p-2 rounded-md w-full justify-between items-center cursor-pointer"
        >
          <p
            className={
              open
                ? 'opacity-100 duration-1000 whitespace-pre p-0 w-0'
                : 'whitespace-pre duration-300 opacity-0 p-0 m-0 w-0'
            }
          >
            Logout
          </p>
          <BiLogOut className={cx('w-8 h-8 cursor-pointer')} />
        </div>
        <div className="flex flex-row justify rounded-sm w-full p-2 justify-between items-center">
          <p
            className={
              open
                ? 'opacity-100 duration-1000 whitespace-pre p-0 m-0 w-0 text-black dark:text-white'
                : 'whitespace-pre opacity-0 p-0 m-0 w-0'
            }
          >
            {theme ? 'Dark Mode' : 'Light Mode'}
          </p>
          <ToggleSwitch
            className={'flex'}
            value={theme}
            onChange={set_toggle_state}
          />
        </div>
      </div>
    </aside>
  );
}

export default SideBar;
